<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <v-text-field
        v-model="value.title"
        outlined
        label="Title"
        hint="Leave blank to disable title"
      />
      <field-card label="Events">
        <v-btn
          text
          color="primary"
          @click="dialog = true"
        >
          Open Calendar
        </v-btn>
        <v-dialog
          v-model="dialog"
          width="800px"
          scrollable
        >
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>
                Events Calendar
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                @click="dialog=false"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row class="fill-height">
                <v-col>
                  <v-sheet height="64">
                    <v-toolbar flat color="white">
                      <v-btn outlined class="mr-4" @click="setToday">
                        Today
                      </v-btn>
                      <v-btn fab text small @click="prev">
                        <v-icon small v-text="'mdi-chevron-left'" />
                      </v-btn>
                      <v-btn fab text small @click="next">
                        <v-icon small v-text="'mdi-chevron-right'" />
                      </v-btn>
                      <v-toolbar-title class="ml-5">
                        {{ title }}
                      </v-toolbar-title>
                      <v-spacer />
                      <v-menu bottom right>
                        <template #activator="{ on }">
                          <v-btn
                            outlined
                            v-on="on"
                          >
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right v-text="'mdi-menu-down'" />
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="type = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-btn
                        class="ml-4"
                        color="primary"
                        outlined
                        @click="editItem({})"
                      >
                        <v-icon left>
                          mdi-plus
                        </v-icon>
                        New Event
                      </v-btn>
                    </v-toolbar>
                  </v-sheet>
                  <v-sheet height="600">
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      :event-color="getEventColor"
                      :event-margin-bottom="3"
                      :now="today"
                      :type="type"
                      @click:event="editItem"
                      @click:more="viewDay"
                      @click:date="viewDay"
                      @change="updateRange"
                    />
                    <v-dialog
                      v-model="itemDialog"
                      max-width="480px"
                      scrollable
                    >
                      <validation-observer
                        v-if="itemDialog"
                        ref="itemForm"
                        v-slot="{ valid }"
                        slim
                      >
                        <v-card>
                          <v-toolbar color="primary" flat dark>
                            <v-toolbar-title>
                              {{ formTitle }}
                            </v-toolbar-title>
                            <v-spacer />
                            <v-btn
                              icon
                              @click="itemDialog = false"
                            >
                              <v-icon>
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-divider />
                          <v-card-text class="pt-4">
                            <v-row>
                              <v-col>
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Event Start"
                                >
                                  <m-datetime-picker
                                    v-model="editedItem.start"
                                    label="Event Start"
                                    :error-messages="errors"
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col>
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Event End"
                                >
                                  <m-datetime-picker
                                    v-model="editedItem.end"
                                    label="Event End"
                                    :min="editedItem.start"
                                    :error-messages="errors"
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Name"
                            >
                              <v-text-field
                                v-model="editedItem.name"
                                outlined
                                label="Name"
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="Description"
                            >
                              <v-textarea
                                v-model="editedItem.description"
                                auto-grow
                                outlined
                                label="Description"
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-card-text>
                          <v-divider />
                          <v-card-actions>
                            <v-btn
                              color="error"
                              text
                              @click="deleteItem"
                            >
                              Delete
                            </v-btn>
                            <v-spacer />
                            <v-btn
                              color="primary"
                              text
                              :disabled="!valid"
                              @click="save"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </validation-observer>
                    </v-dialog>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                outlined
                @click="dialog=false"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </field-card>
      <field-card label="Card Design">
        <v-radio-group
          v-model="value.cols"
          row
          required
        >
          <template #label>
            <div>How many cards should be displayed on the screen at one time?</div>
          </template>
          <v-radio
            v-for="n in 6"
            :key="n"
            :label="`${n}`"
            :value="n"
          />
        </v-radio-group>
        <m-color-input
          v-model="value.cardColor"
          label="Background Color"
        />
        <v-row class="flex-wrap">
          <v-switch
            v-model="value.cardDark"
            class="mx-5"
            label="Dark Mode"
          />
          <v-switch
            v-model="value.cardShaped"
            class="mx-5"
            label="Shaped Style"
          />
          <v-switch
            v-model="value.cardFlat"
            class="mx-5"
            label="No Shadows"
          />
        </v-row>
      </field-card>
      <field-card label="Dialog Design">
        <m-color-input
          v-model="value.dialogColor"
          label="Background Color"
        />
        <v-row class="flex-wrap">
          <v-switch
            v-model="value.dialogDark"
            class="mx-5"
            label="Dark Mode"
          />
        </v-row>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  name: 'MCalendarForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    dialog: false,
    events: [],
    today: moment().format('YYYY-MM-DD'),
    focus: moment().format('YYYY-MM-DD'),
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day'
    },
    start: null,
    end: null,
    editedIndex: -1,
    editedItem: {},
    itemDialog: false,
    textFieldProps: {
      outlined: true,
      required: true
    },
    datePickerProps: {},
    timePickerProps: {}
  }),
  computed: {
    title() {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }
      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth
      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear
      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)
      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Event' : 'Edit Event'
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long'
      })
    }
  },
  watch: {
    value: {
      handler({ items }) {
        this.events = items.map((event) => {
          if (event.start) {
            event.start = this.formatDate(event.start, 'YYYY-MM-DD HH:mm')
          }
          if (event.end) {
            event.end = this.formatDate(event.end, 'YYYY-MM-DD HH:mm')
          }
          return event
        })
      },
      immediate: true
    },
    dialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.calendar && this.$refs.calendar.checkChange()
        })
      }
    },
    itemDialog(val) {
      val || this.close()
    }
  },
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color || 'primary'
    },
    setToday() {
      this.focus = this.today
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    updateRange({ start, end }) {
      this.start = start
      this.end = end
    },
    nth(d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    editItem({ nativeEvent, event }) {
      nativeEvent && nativeEvent.stopPropagation()
      this.editedIndex = this.events.indexOf(event)
      this.editedItem = Object.assign({}, event)
      this.itemDialog = true
    },
    deleteItem() {
      if (confirm('Are you sure you want to delete this item?')) {
        this.events.splice(this.editedIndex, 1)
        this.itemDialog = false
        const items = this.events
        this.$emit('input', { items })
      }
    },
    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save() {
      let items = this.events
      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem)
      } else {
        items = [...items, this.editedItem]
      }
      this.$emit('input', { items })
      this.itemDialog = false
    }
  }
}
</script>
